// Generated by Framer (0f540f8)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Ticker from "https://framerusercontent.com/modules/B2xAlJLcN0gOnt11mSPw/XVUmpmPn1EPL0dzocT35/Ticker.js";
const TickerFonts = getFonts(Ticker);

const cycleOrder = ["PNSYRlXmf"];

const serializationHash = "framer-5DYzF"

const variantClassNames = {PNSYRlXmf: "framer-v-11zjpe2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "PNSYRlXmf", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-11zjpe2", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PNSYRlXmf"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><ComponentViewportProvider ><motion.div className={"framer-1pp4cml-container"} layoutDependency={layoutDependency} layoutId={"JPn4kqWNp-container"}><Ticker alignment={"center"} direction={"left"} fadeOptions={{fadeAlpha: 0, fadeContent: true, fadeInset: 0, fadeWidth: 25, overflow: false}} gap={10} height={"100%"} hoverFactor={1} id={"JPn4kqWNp"} layoutId={"JPn4kqWNp"} padding={10} paddingBottom={10} paddingLeft={10} paddingPerSide={false} paddingRight={10} paddingTop={10} sizingOptions={{heightType: true, widthType: true}} slots={[]} speed={100} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5DYzF.framer-tmsz73, .framer-5DYzF .framer-tmsz73 { display: block; }", ".framer-5DYzF.framer-11zjpe2 { height: 58px; position: relative; width: 769px; }", ".framer-5DYzF .framer-1pp4cml-container { flex: none; height: 100%; left: 0px; position: absolute; right: 0px; top: calc(50.00000000000002% - 100% / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 58
 * @framerIntrinsicWidth 769
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerswIuJmlyH: React.ComponentType<Props> = withCSS(Component, css, "framer-5DYzF") as typeof Component;
export default FramerswIuJmlyH;

FramerswIuJmlyH.displayName = "Banner";

FramerswIuJmlyH.defaultProps = {height: 58, width: 769};

addFonts(FramerswIuJmlyH, [{explicitInter: true, fonts: []}, ...TickerFonts], {supportsExplicitInterCodegen: true})